/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
:root {
  --primaryColor: #333;
}

.not-found {
  margin: 20px auto;
}

.not-found__title {
  font-size: 28px;
  font-family: "OpenSans-Bold", arial, sans-serif;
  line-height: 38px;
  margin-bottom: 20px;
}

.not-found__subtitle {
  font-size: 13px;
  margin-bottom: 25px;
}

.not-found__image {
  max-width: 100%;
}

.not-found__bottom-info {
  font-size: 19px;
  font-family: "OpenSans-Bold", arial, sans-serif;
  margin: 0 0 20px;
}
@media (min-width: 769px) {
  .not-found__bottom-info {
    margin: 50px auto 20px;
  }
}

.not-found__phone {
  color: #0070d2;
}

.not-found__button {
  display: block;
  text-transform: uppercase;
  font-family: "OpenSans-Light", arial, sans-serif;
}
@media (min-width: 769px) {
  .not-found__button {
    width: 42%;
    margin: 0 auto 50px;
  }
}
@import "variables";
@import "base/fonts";
@import "bootstrap/scss/mixins/breakpoints";

.not-found {
    margin: 20px auto;
}

.not-found__title {
    font-size: 28px;
    font-family: $opensans-bold;
    line-height: 38px;
    margin-bottom: 20px;
}

.not-found__subtitle {
    font-size: 13px;
    margin-bottom: 25px;
}

.not-found__image {
    max-width: 100%;
}

.not-found__bottom-info {
    font-size: 19px;
    font-family: $opensans-bold;
    margin: 0 0 20px;

    @include media-breakpoint-up(md) {
        margin: 50px auto 20px;
    }
}

.not-found__phone {
    color: $blue;
}

.not-found__button {
    display: block;
    text-transform: uppercase;
    font-family: $opensans-light;

    @include media-breakpoint-up(md) {
        width: 42%;
        margin: 0 auto 50px;
    }
}
